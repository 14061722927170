@import '../Colours.scss';

.datepicker-container {
  background: #fff;
  padding: 5px 0 5px 0;
  border-radius: 3.5px;
  width: 100%;
  height: 38px;
  border: 1px solid #EBEDF2;
  color: hsl(0,0%,20%);

  .calendar-showing {
    pointer-events: none;
    .indicatorContainer {
      color: hsl(0,0%,60%);
    }
  }

  .datepicker-dates {
    border: none;
    padding: 0 0 0 8px;
    color: hsl(0,0%,20%);
    min-width: 270px;
    max-width: 290px;
  }

  .indicatorsContainer {
    cursor: pointer;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-align-self: stretch;
    -ms-flex-item-align: stretch;
    align-self: stretch;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    box-sizing: border-box;
    margin-top: -5px;
    margin-bottom: -5px;
  }

  .indicatorSeparator {
    -webkit-align-self: stretch;
    -ms-flex-item-align: stretch;
    align-self: stretch;
    background-color: hsl(0,0%,80%);
    margin-bottom: 8px;
    margin-top: 8px;
    width: 1px;
    box-sizing: border-box;
  }

  .indicatorContainer {
    color: hsl(0,0%,80%);
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding: 8px;
    -webkit-transition: color 150ms;
    transition: color 150ms;
    box-sizing: border-box;
    &:hover { color: hsl(0,0%,60%); }
  }

  .dropdownArrow {
    display: inline-block;
    fill: currentColor;
    line-height: 1;
    stroke: currentColor;
    stroke-width: 0;
  }

  svg {
    overflow: hidden;
    vertical-align: middle;
  }

  .display-range {
    padding: 0px 8px;
  }
}

// Styles for the open calendar
.daterangepicker {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  box-shadow: rgba(113, 106, 202, 0.2) 0px 0px 15px 1px;
  border: 1px solid #fff;

  .ranges {
    padding-bottom: 0px;

    li:hover {
      background-color: #f9f9fd;
    }
    li.active {
      background-color: #716aca;
   }
  }
  td.active, .daterangepicker td.active:hover {
    background-color: #5867dd;
    border-color: 1px solid #5867dd;
    color: #fff;
  }
  td.in-range {
    background-color: #5867dd;
    color: #fff;
  }
  td.available:hover, th.available:hover {
    background-color: #5867dd;
    color: #fff;
  }
  .drp-selected {
    display: none;
  }
  .drp-buttons {
    border-top: none;
    padding-top: 0px;

    .btn {
      font-weight: 400;
      padding: .6rem 1.6rem;
      border-radius: 40px;
    }
    .applyBtn {
      background-color: rgb(52, 191, 163);
      color: white;
      border-color: rgb(52, 191, 163);
    }
    .cancelBtn {
     background-color: white;
     border-color: rgb(235, 237, 242);
    }
  }
 .ranges ul li {
    border-color: transparent;
  }
  .ranges li {
    color: $text_grey;
  }

  .calendar-time {
    select {
      border-radius: 4px;
      border: 1px solid #EBEDF2;
      background-color: #fff;
      color: $text_grey;
    }
  }
}
