@import '../../Colours.scss';

.dashboard {
  .nav-wrapper {
    box-shadow: none;
  }

  .main-content {
    padding-top: 10px;
  }

  .center-column {
    margin-left: auto;
    margin-right: auto;

    @media only screen and (max-width: 1399px) {
      padding-left: 63px;
      padding-right: 63px;
    }
    @media only screen and (max-width: 992px) {
      padding-left: 53px;
      padding-right: 53px;
    }
    @media only screen and (min-width: 1400px) {
      padding-left: 30px;
      padding-right: 30px;
      width: 1340px;
    }
  }

  .title-row {
    margin-bottom: 15px;
    min-height: 39px;
    #dashboard-title {
      font-size: 2.3rem;
      margin-bottom: 0;
      word-break: break-word;
    }
  }

  h3.chart-title {
    font-weight: 300;
    font-size: 22px;
  }

  h5 {
    font-size: 18px;
    margin-bottom: 12px;
  }

  h4 {
    font-size: 20px;
    font-weight: 300;
  }

  .tab-stat-container {
    margin-top: -10px;
  }

  .tab-stat {
    line-height: 20px;
    font-size: 16px;
    color: #575962;
    &.label {
      font-weight: 300;
    }
    &.value {
      font-weight: 500;
    }
  }

  .scrollable-grid {
    overflow-x: auto;
    &::-webkit-scrollbar {
      -webkit-appearance: none;
      width: 0px;
      height: 8px;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 5px;
      background-color: rgba(0, 0, 0, .5);
    }
  }

  .tabs-grid {
    margin-top: 3px;
    grid-template-columns: 250px 1fr;
    .grid-scrollable-column {
      border-top: 1px solid #cccccc;
      height: 0;
      min-height: 100%;
      overflow-y: auto;
      &.loading-grid {
        min-height: 142px;
      }

      &::-webkit-scrollbar {
        -webkit-appearance: none;
        width: 8px;
        height: 0px;
      }
      &::-webkit-scrollbar-thumb {
        border-radius: 5px;
        background-color: rgba(0, 0, 0, .5);
      }
      &::-webkit-scrollbar-track {
        border-bottom: 1px solid #cccccc;
        border-left: 1px solid #cccccc;
      }
    }

    .grid-background {
      background: linear-gradient(to bottom, #271e3f, #6950a5, #271e3f);
    }

    .nav-item {
      min-height: 130px;
      padding: 0px 0px 0px 4px;
      background-color: #f0ebf9b3;
      cursor: pointer;
      &:has(.active) {
        background-color: unset;
      }
      &:hover {
        background-color: rgba(240, 235, 249, 0.2);
        .nav-link {
          background: #fcfcfc;
          canvas {
            opacity: 1;
          }
          .tab-stat.value, svg {
            opacity: 1;
          }
        }
      }

      .nav-link {
        min-height: 130px;
        color: #575962;
        canvas {
          opacity: 0.6;
        }
        .tab-stat.value, svg {
          opacity: 0.8;
        }
        flex-grow: 1;
        border-top: 1px solid #cccccc;
        border-radius: 0;
        background: #f0f0f0;
        .fav-star {
          color: #5867dde6;
        }
        h3 {
          font-size: 18px;
          font-weight: 300;
        }
        &.active {
          background: white;
          canvas, .tab-stat.value, svg {
            opacity: 1;
          }
        }
        &:hover {
          text-decoration: none;
        }
      }
      &:first-of-type {
        .nav-link {
          border-top: none;
        }
      }
      &:last-of-type {
        .nav-link {
          border-bottom: 1px solid #cccccc;
        }
      }
    }

    .grid-tab-content-area {
      min-height: 500px;
      background: white;
      border-left: 1px solid #cccccc;
      border-top: 1px solid #cccccc;
      border-right: 1px solid #cccccc;
      border-bottom: 1px solid #cccccc;
    }
  }

  .live-sessions {
    background: linear-gradient(to right, #6950a5, #342852);
    color: white;
    border-radius: 0.25rem;
  }

  .loading-text-placeholder {
    height: 20px;
    vertical-align: middle;
    width: 70px;
    background-color: #e6e6e6;
    border-radius: 2px;
  }
  .loading-bar-placeholder {
    height: 40px;
    vertical-align: middle;
    width: 150px;
    background-color: #e6e6e6;
    border-radius: 2px;
  }

  .skeleton-background {
    animation: skeleton-background-loading 1s linear infinite alternate;
  }
  @keyframes skeleton-background-loading {
    0% {
      background-color: #f9fafb;
    }
    100% {
      background-color: #e1e7ea;
    }
  }
  .skeleton-color {
    animation: skeleton-color-loading 1s linear infinite alternate;
  }
  @keyframes skeleton-color-loading {
    0% {
      color: #e1e7ea;
    }
    100% {
      color: #6C6F7A;
    }
  }


  .completion-value-input-area {
    background: #f5f5f5;
    border-radius: 0.25rem;
    padding: 4px 10px;
    label {
      font-weight: 500;
    }
    .input-group {
      width: fit-content;
    }
    .alert {
      width: fit-content;
    }
    button, .alert {
      padding: 5px 10px;
    }

    .alert-outline-info {
      background-color: whitesmoke;
      border-color: #ccc;
      color: #82848a;
    }

    button {
      &.btn-secondary {
        background-color: #006ec2;
        border-color: #006ec2;
      }
      &.btn-outline-secondary {
        border-color: #006ec2;
        color: #006ec2;
      }
      &:hover, &:active {
        color: white;
        background-color: #006ec2;
        border-color: #006ec2;
      }
    }
  }

  .completion-value-input-area, .live-sessions {
    .text-more-info {
      text-decoration: underline;
      text-decoration-style: dotted;
      text-decoration-thickness: 1.3px;
      text-underline-offset: 3px;
      cursor: pointer;
    }
  }

  .completion-value-input-area {
    .text-more-info {
      text-decoration-color: #64656e;
      &:hover {
        color: #494a4f;
        text-decoration-color: #414246;
      }
    }
  }

  .estimated-abandon-box {
    padding: 2px 5px;
    background: rgba(245, 245, 245, 0.3);
    font-style: italic;
    border-radius: 0.25rem;
    width: fit-content;
  }

  .value-increase-container {
    background: #f5f5f5;
    padding: 4px 10px;
    border-radius: 0.25rem;
  }

  #completion-currency {
    cursor: pointer;
    max-width: 60px;
    background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27%3e%3cpath fill=%27none%27 stroke=%27%23a0a3ab%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%272%27 d=%27m2 5 6 6 6-6%27/%3e%3c/svg%3e");
    background-color: #f5f5f5;
    color: #6c6f7a;
    transition: all .2s ease-in-out;
    &:hover {
      color: #3f4047;
      background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27%3e%3cpath fill=%27none%27 stroke=%27%233f4047%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%272%27 d=%27m2 5 6 6 6-6%27/%3e%3c/svg%3e");
    }
  }

  #completion-value {
    max-width: 100px;
  }

  #datepicker {
    max-width: fit-content;
    min-width: 200px;
  }
  .filter-buttons-wrapper {
    border-radius: 3.5px;
    border: 1px solid #EBEDF2;
    padding: 5px;

    .btn-check:checked + .filter-button.btn {
      color: #5867dde6;
      border-color: #5867dd;
      background: #5867dd1c;
    }
    .btn-check + .btn:hover {
      color: #5867dde6;
      border-color: #5867dd;
      background: #5867dd1c;
    }
    .filter-button {
      font-size: 14px;
      border-radius: 50px;
      padding: 3px 14px;
      border-color: #aeb0b7;
      color: #7d808c;
      svg {
        margin-left: -6px;
      }
    }
  }

  .completion-rate-vis {
    padding: 10px 40px 10px 20px;
  }

  .completion-rate-chart {
    max-height: 200px;
    max-width: 375px;
  }

  .floating-bar-chart {
    width: 100%;
    max-width: 200px;
    height: 60px;
  }


  .abandon-bar-table {
    margin: 5px 15px 7px 15px;

    .text-more-info {
      text-decoration: underline;
      text-decoration-style: dotted;
      text-underline-offset: 3px;
      cursor: pointer;
    }

    .abandon-field-label {
      font-size: 1.1rem;
    }

    .table-responsive {
      &::-webkit-scrollbar {
        -webkit-appearance: none;
        width: 8px;
        height: 8px;
      }
      &::-webkit-scrollbar-thumb {
        border-radius: 5px;
        background-color: rgba(0, 0, 0, .5);
      }
    }

    table {
      --bs-table-hover-bg: #fafafa;

      thead {
        position: sticky;
        top: 0;
        z-index: 3;
      }
      tr {
        height: fit-content;
      }
      tr > th {
        white-space: unset;
        word-break: break-word;
      }
      th {
        font-size: 15px;
        background-color: #fff;
        padding: 0px;

      }
      .table-body {
        tr {
          td {
            padding: 3px 3px 3px 0px;
            font-size: 14px;
            border-bottom: 1px solid #ebedf3;
            &.cell-header-label {
              text-align: start;
            }
          }
          &:hover {
            td {
              background-color: #fafafa;
            }
          }
          &:last-of-type {
            td {
              border-bottom: 0;
            }
          }
        }
      }
    }

    .abandon-chart-grid {
      cursor: pointer;
      display: grid;
      grid-template-rows: 1fr;
      grid-template-columns: repeat(100, 1fr);
      width: 100%;
      padding: 1px;
      background-color: whitesmoke;
      border-radius: 0.25rem;
    }

    [class*="bar"] {
      border-radius: 0px 5px 5px 0px;
      transition: all .6s ease;
      height: 25px;
      &.currency-usd {
        background: url('data:image/svg+xml,<svg stroke="%23F0764A" fill="%23F0764A" stroke-width="0" viewBox="7 0 10 24" height="200px" width="100px" xmlns="http://www.w3.org/2000/svg"><path d="M15.999 8.5h2c0-2.837-2.755-4.131-5-4.429V2h-2v2.071c-2.245.298-5 1.592-5 4.429 0 2.706 2.666 4.113 5 4.43v4.97c-1.448-.251-3-1.024-3-2.4h-2c0 2.589 2.425 4.119 5 4.436V22h2v-2.07c2.245-.298 5-1.593 5-4.43s-2.755-4.131-5-4.429V6.1c1.33.239 3 .941 3 2.4zm-8 0c0-1.459 1.67-2.161 3-2.4v4.799c-1.371-.253-3-1.002-3-2.399zm8 7c0 1.459-1.67 2.161-3 2.4v-4.8c1.33.239 3 .941 3 2.4z"></path></svg>') 0 0 / 18px 25px repeat-x;
      }
      &.currency-eur {
        background: url('data:image/svg+xml,<svg stroke="%23F0764A" fill="%23F0764A" stroke-width="0" viewBox="6 0 10 24" height="200px" width="130px" xmlns="http://www.w3.org/2000/svg"><path d="M13.464 6c1.43 0 2.779.613 3.799 1.726l1.475-1.352C17.334 4.843 15.461 4 13.464 4c-1.998 0-3.87.843-5.272 2.375A8.034 8.034 0 0 0 6.589 9H4v2h2.114c-.038.33-.064.663-.064 1s.026.67.064 1H4v2h2.589c.362.97.901 1.861 1.603 2.626C9.594 19.157 11.466 20 13.464 20c1.997 0 3.87-.843 5.273-2.374l-1.475-1.352C16.243 17.387 14.894 18 13.464 18s-2.778-.612-3.798-1.726A5.937 5.937 0 0 1 8.801 15H13v-2H8.139c-.05-.328-.089-.66-.089-1s.039-.672.089-1H13V9H8.801c.24-.457.516-.893.865-1.274C10.686 6.613 12.034 6 13.464 6z"></path></svg>') 0 0 / 18px 25px repeat-x;
      }
      &.currency-gbp {
        background: url('data:image/svg+xml,<svg stroke="%23F0764A" fill="%23F0764A" stroke-width="0" viewBox="8 0 8 24" height="200px" width="115px" xmlns="http://www.w3.org/2000/svg"><path d="m5.553 19.105.764 1.843C7.498 20.555 9.422 20 10 20c.838 0 1.462.208 2.184.448.775.259 1.654.552 2.816.552 1.177 0 3.078-.921 3.447-1.105l-.895-1.789c-.721.36-2.031.894-2.552.894-.838 0-1.462-.208-2.184-.448C12.041 18.293 11.162 18 10 18c-.229 0-.526.037-.857.099C9.702 16.95 10 15.561 10 14h3v-2H9.626c-.042-.107-.084-.216-.125-.317C9.243 11.052 9 10.455 9 9c0-1.369.521-3 3-3 2.224 0 3.021 2.227 3.052 2.316l1.896-.633C16.898 7.533 15.679 4 12 4 8.313 4 7 6.583 7 9c0 1.491.234 2.35.478 3H5v2h3c0 2.467-.892 4.328-2.447 5.105z"></path></svg>') 0 0 / 18px 25px repeat-x;
      }
      grid-column-start: 1;
      grid-column-end: 101;
      display: flex;
    }

    $totalColumns: 101;

    @mixin chartValue($data) {
      // $result: $totalColumns - $data;
      grid-column-end: $data;
    }

    @for $i from 1 through $totalColumns {
      .bar-#{$i} {
        @include chartValue($i);
      }
    }

  }

  .progress-area, .error-msg-area {
    min-height: 250px;
  }
  .progress-area {
    .progress {
      flex-basis: 0;
      flex-grow: 1;
      max-width: 100%
    }
    .progress-bar {
      background-color: #34bfa3;
    }
  }

  .inner-card {
    border: 1px solid #dadee7;
    border-radius: 0.25rem;
    h4 {
      text-align: center;
    }

    .tagline {
      color: grey;
    }

    svg.info {
      color: #949494;
      &:hover {
        color: #575962;
      }
    }
  }

  .go-to-page-btn {
    padding: 5px 10px;
    transition: all .35s ease-in-out;
    box-shadow: 0 1px 6px 1px rgb(234, 227, 247);
    height: fit-content;
    a {
      color: #664fa1;
      text-decoration: none;
    }
    color: #664fa1;
    border-color: #664fa1;
    background-color:  white;
    &:hover, &:focus, &:active, &:not(:disabled):not(.disabled):active {
      background-color: #664fa1;
      border-color: #664fa1;
      color: white;
      a {
        text-decoration: none;
      }
    }
    &:focus, &:active, &:active:focus {
      box-shadow: 0 0 0 0.2rem rgb(102, 79, 161, 0.5);
      border-color: #664fa1;
    }
  }

  .btn-less-padding {
    padding: 7px 15px;
  }

  .icon-in-p {
    margin-top: -3px;
  }

  .card {
    &.fav-forms-card {
      border: 1px solid #919cee;
    }
    margin-bottom: 2.2rem;
    .card-body {
      padding: 20px 2rem 20px 2rem;
      .card-title-row {
        .card-title {
          font-size: x-large;
          color: $title_text_grey;
        }
      }
      .card-vis {
        padding-top: 0.5rem;
        padding-bottom: 1rem;
      }
    }

    .session-outcomes-doughnut-chart, .attributes-doughnut-chart {
      max-height: 250px;
    }

    .card-tagline {
      font-weight: 400;
      font-size: 1.1rem;
    }

    .session-usage-stats {
      color: $title_text_grey;
      font-weight: 200;
      font-size: 18px;
    }

    .stats-current {
      font-size: 22px;
      line-height: 18px;
      color: rgb(87, 89, 98);
    }

    .stats-previous {
      font-size: 0.9rem;
      font-weight: 400;
      color: rgb(150, 150, 150);
      min-height: 18.9px;
      width: fit-content;
      margin: auto;

      .caret-down {
        color: #f77783;
      }
      .caret-up {
        color: #64ba7f;
      }
    }
  }

  .tracking-warning-alert {
    background-color: rgba(251, 223, 113, 0.24);
    color: #615d5d;
    a {
      color: #615d5d;
    }
  }

  .form-grid {
    padding-top: 10px;
    display: grid;
    grid-column-gap: 20px;
    grid-row-gap: 20px;
    grid-template-rows: auto;
    grid-template-columns: repeat(auto-fit, minmax(250px, 0.33fr));
    margin-bottom: 20px;
    a:hover {
      text-decoration: none;
    }
  }

  .form-summary {
    &:only-child {
      margin-right: 7px;
    }
    min-height: 105px;
    padding: 10px;
    box-shadow: 0 1px 6px 1px rgb(234, 227, 247);
    border-radius: 5px;

    h3 {
      font-size: 24px;
      font-weight: 200;
      color: $title_text_grey;
    }

    .error-msg {
      color: #6C6F7A;
      text-align: center;
    }

    transition-property: color, background-color, box-shadow;
    transition-duration: .2s;
    transition-timing-function: ease-in-out;
    &:hover {
      background-color: rgba(240, 235, 249, 0.54);
      box-shadow: 0 1px 6px 1px #e1d7f4;
      cursor: pointer;
      text-decoration: none;
      color: #474849;
      h3 {
        color: #3a3a3b;
      }
    }

    .fav-star-outline {
      color: #d2d3e0;
      transition: all .2s ease-in-out;
      &:hover {
        color: #5867dde6;
      }
    }

    .fav-star {
      color: #5867dde6;
      transition: all .2s ease-in-out;
    }
  }

  .plan-fade-enter {
    opacity: 0;
  }
  .plan-fade-enter-active {
    opacity: 1;
    transition: opacity 300ms;
  }
  .plan-fade-exit {
    opacity: 1;
  }
  .plan-fade-exit-active {
    opacity: 0;
    transition: opacity 200ms;
  }

  .alert-row {
    .alert-svg-icon {
      max-width: 32px;
    }
  }

  .sort-tools-row {
    .form-label {
      font-size: 1.1rem;
    }
  }

  [id^="sort-select-"] {
    min-width: 200px;
  }
}

.dashboard-forms-fade-enter, .dashboard-forms-fade-appearx {
  .form-summary {
    opacity: 0.5;
    h3, p {
      opacity: 0;
    }
    .alert {
      opacity: 0;
      transition: opacity 0ms linear;
    }
  }
}
.dashboard-forms-fade-enter-active, .dashboard-forms-fade-appear-activex {
  h3, p {
    opacity: 1;
    transition: opacity 100ms linear;
  }
  &.form-summary {
    opacity: 1;
    transition: opacity 100ms linear;
  }
  .alert {
    opacity: 1;
    transition: opacity 100ms linear;
  }
}
.dashboard-forms-fade-exit {
  &.form-summary {
    opacity: 0.5;
    h3, p {
      opacity: 0;
    }
    .alert {
      opacity: 0;
      transition: opacity 0ms linear;
    }
  }
}

.dashboard-popover {
  .popover-body {
    color: #6C6F7A;
  }
}