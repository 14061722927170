@import '../../Colours.scss';

.org-edit {
  .main-content {
    padding-top: 10px;
  }

  .center-column {
    margin-left: auto;
    margin-right: auto;

    @media only screen and (max-width: 1399px) {
      padding-left: 63px;
      padding-right: 63px;
    }
    @media only screen and (max-width: 992px) {
      padding-left: 53px;
      padding-right: 53px;
    }
    @media only screen and (min-width: 1400px) {
      padding-left: 30px;
      padding-right: 30px;
      width: 1340px;
    }
  }

  .min-height-tab {
    min-height: 300px;
  }

  .title-row {
    margin-bottom: 30px;
    min-height: 39px;
    #form-title {
      padding: 7px 25px 7px 0;
      margin-bottom: 0;
    }
  }

  .top-form-row {
    padding-left: 2.2rem;
    padding-right: 2.2rem;
    white-space: nowrap;
  }

  .edit-card {
    margin-bottom: 2.2rem;
  }

  .card-body {
    padding: 20px 2.2rem 2rem 2.2rem;

    .card-header-tabs .sign-up-btn {
      margin-top: -3px;
      margin-bottom: 3px;
    }
  }

  .form-readonly-info {
    input[type=checkbox] {
      filter: hue-rotate(18deg); // Change the native colour of the checkbox
      height: 18px;
      width: 18px;
      cursor: pointer;
      margin-top: 0;
    }
  }

  #users-info-alert {
    .alert {
      margin-bottom: 0;
    }
  }

  .card-title-row {
    // To spread the underline across the top of the card
    margin-left: -2.2rem;
    padding-left: 2.2rem;
    margin-right: -2.2rem;
    padding-right: 2.2rem;
    padding-bottom: 20px;
    border-bottom: 1px solid #ebedf2;

    .card-title {
      font-size: x-large;
      color: #575962;
    }

    #card-header-nav-tabs {
      margin-right: 0;
      .nav-item {
        padding-right: 30px;
        padding-left: 5px;
        margin-bottom: 0;

        border-bottom: 1px solid #ebedf2;
        &:first-child {
          padding-left: 0;
        }
        &:last-child {
          padding-right: 0;
        }
        .nav-link {
          border: none;
          padding: 0 0 8px 0;
          margin-bottom: -1px;
          color: $text_grey;
          font-size: 1.1rem;
          &:hover {
              color: #3f4047;
              border-bottom: 1px solid #3f4047;
          }
          &.active {
            color: #3f4047;
            border-bottom: 1px solid #3f4047;
          }
          &.disabled {
            color: #9a9a9a;
          }
        }
      }
    }
  }

  .tab-content {
    width: 100%;
  }

  .card-content {
    padding-top: 15px;
  }

  .form-title-row {
    line-height: 18px;
    color: $text_grey;
    padding-top: 15px;
    padding-bottom: 15px;
    h3 {
      font-size: 1.2rem;
      margin-bottom: 0;
    }
  }

  #org-load-error {
    text-align: center;
    h3 {
      font-size: 1.2rem;
    }
  }

  .alert-danger {
    .alert-text {
      &::first-letter {
        text-transform: uppercase;
      }
    }
  }

  .invalid-input {
    border-color: #dc3545;
    &:focus {
      box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
    }
  }

  #users-table {
    margin-bottom: 0;
    .cancel, .submit, .action-btn {
      padding-right: 14px;
      padding-left: 14px;
    }
    .action-btn {
      width: 115px;
      background-color: #5867dd;
      border-color: #5867dd;
      white-space: nowrap;
      &:hover {
        background-color: #4042a5;
        border-color: #4042a5;
      }
    }

    .invite {
      padding-right: 0;
      padding-left: 0;
      width: 115px;
    }

    .form-control::placeholder {
      opacity: 0.6;
    }

    i.info {
      font-size: 20px;
      align-self: center;
      padding: 4px;
    }

    thead {
      color: $text_grey;
    }

    thead > tr > th {
      vertical-align: middle;
      border-top: 0;
      font-size: 1.2rem;
      font-weight: 500;
      &:last-of-type {
        white-space: nowrap;
        width: 1%;
      }
    }

    tbody > tr {
      &:last-of-type {
        border-top: 2px solid #dee2e6;
        td {
          padding-top: 1rem;
        }
        form {
          padding-top: 0.5rem;
        }
      }
    }

    tbody > tr > td {
      color: #3f4047;
      vertical-align: middle;
      &:last-of-type {
        white-space: nowrap;
        width: 1%;
      }
    }

    .create-account-col {
      width: 30%;
    }
  }

  .column-info-icon {
    vertical-align: top;
    margin-left: 2px;
  }

  .role-select-col {
    width: 1%;
  }

  .role-container {
    margin-right: 20px;
  }

  .new-user-form {
    .role-container {
      padding-right: 10.5px;
    }
  }

  .role-select {
    width: 110px;
    &:disabled {
      background-image: none;
    }
  }

  #forms-table {
    thead {
      color: $text_grey;
    }

    thead > tr > th {
      vertical-align: middle;
      border-top: 0;
      font-size: 1.2rem;
      font-weight: 500;
      &.link-col {
        width: 1%;
      }
    }

    tbody > tr > td {
      color: #3f4047;
      vertical-align: middle;
      &.link-col {
        width: 1%;
        a {
          font-weight: 500;
        }
        white-space: nowrap;
        text-align: center;
      }
    }

    .tracking-warning-alert {
      min-height: 18.9px;
      width: fit-content;
      padding: 2px 10px;
      background-color: rgba(251, 223, 113, 0.24);
      color: #615d5d;
      a {
        color: #615d5d;
      }
    }

    tbody > tr {
      &:last-of-type {
        border-bottom: 2px solid #dee2e6;
      }
    }
  }

  .action-btn {
    padding-right: 14px;
    padding-left: 14px;
  }

  #ip-filters-table {
    .btn-light {
      border: none;
    }

    .cancel, .submit {
      padding-right: 14px;
      padding-left: 14px;
    }

    .form-control::placeholder {
      opacity: 0.6;
    }

    i.editing {
      font-size: 1.2rem;
    }

    thead {
      color: $text_grey;
    }

    thead > tr > th {
      vertical-align: middle;
      border-top: 0;
      font-size: 1.2rem;
      font-weight: 500;
      &:last-of-type {
        width: 5%;
      }
    }

    tbody > tr {
      &:last-of-type {
        border-top: 2px solid #dee2e6;
      }
    }

    tbody > tr > td {
      color: #3f4047;
      vertical-align: top;
      &:last-of-type {
        text-align: center;
        white-space: nowrap;
        width: 5%;
        vertical-align: middle;
        padding-right: 1.5rem;
      }
    }

    .validationFailureMessage {
      color: #e01f46;
      font-size: 12px;
    }
  }

  #org-clients-table, #org-session-limits-table {
    thead {
      color: $text_grey;
    }

    thead > tr > th {
      vertical-align: middle;
      border-top: 0;
      font-size: 1.2rem;
      font-weight: 500;
      &.link-col {
        width: 1%;
      }
    }

    tbody > tr > td {
      color: #3f4047;
      vertical-align: middle;
      &.link-col {
        width: 1%;
        font-weight: 500;
        white-space: nowrap
      }
    }

    tbody > tr {
      &:last-of-type {
        border-bottom: 2px solid #dee2e6;
      }
    }
  }

  #org-session-limits-table {
    th {
      height: 55px;
    }
    .name-col {
      width: 30%;
    }
    .limit-col {
      padding-right: 0;
    }

    .total-msg {
      color: $title_text_grey;
      font-weight: 200;
      font-size: 18px;
      text-align: right;
    }

    .session-limit-input {
      max-width: 200px;
    }

    .invalid-limit-feedback {
      font-size: 80%;
      color: #dc3545;
      margin-bottom: -8px;
      margin-top: 0.25rem;
    }
  }

  .form-select:disabled, .form-control:disabled {
    background-color: whitesmoke;
    transition: all .25s ease-in-out;
  }

  .form-select[readonly], .form-control[readonly] {
    background-color: whitesmoke;
    color: #575962;
    border-color: #ebedf2;
    &:focus {
      border-color: #ebedf2;
      box-shadow: none;
    }
  }
}

#standard-access-msg-popover {
  .popover-body {
    max-width: 300px;
  }
}